import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//
const tabTriggers = document.querySelectorAll('.tabs-nav span');

[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', switchTab);
});

function switchTab(e) {
	let targetTab = e.currentTarget.dataset.tab;

	removeActive('.tab[data-active]');
	removeActive('.tabs-nav span[data-active]');

	e.currentTarget.dataset.active = true;
	document.querySelector(`.tab[data-tab='${targetTab}']`).dataset.active = true;
}

//------------------------------------//
// TABS - SELECT
//------------------------------------//
const inputTrigger = document.querySelector('select.tabs-nav');

inputTrigger &&
	inputTrigger.addEventListener('change', (e) => {
		e.preventDefault();

		let elTarget = e.currentTarget.value;

		removeActive('.tab[data-active]');

		//trigger.dataset.active = true;
		document.querySelector(
			`.tab[data-tab='${elTarget}']`
		).dataset.active = true;
	});

//------------------------------------//
// SWIPER - NEWS
//------------------------------------//
const swiperNews = new Swiper('.swiper-news', {
	// Optional parameters
	loop: true,
	slidesPerView: 1.25,
	spaceBetween: 20,

	// If we need pagination
	pagination: {
		el: '.grid .swiper-pagination',
		type: 'fraction'
	},

	// Navigation arrows
	navigation: {
		nextEl: '.grid .button-next',
		prevEl: '.grid .button-prev'
	},
	breakpoints: {
		// when window width is >= 320px
		600: {
			slidesPerView: 1.5
		},
		// when window width is >= 480px
		768: {
			slidesPerView: 1.75
		},
		// when window width is >= 640px
		992: {
			slidesPerView: 2,
			spaceBetween: 30
		},
		1024: {
			slidesPerView: 2.5,
			spaceBetween: 50
		}
	}
});

//------------------------------------//
// SWIPER - HERO
//------------------------------------//
const swiperHero = new Swiper('.swiper-hero', {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	effect: 'fade',
	autoplay: {
		delay: 3000
	},

	// If we need pagination
	pagination: {
		el: '.hero .swiper-pagination',
		clickable: true,
		renderBullet: function (index, className) {
			return `<span class="${className}">
				  <svg class="fp-arc-loader" width="32" height="32" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="15" fill="none" transform="rotate(-90 16 16)" stroke="#FFF" stroke-opacity=".3" stroke-width="2px"></circle>
				    <circle class="path" cx="16" cy="16" r="15" fill="none" transform="rotate(-90 16 16)" stroke="#FFF" stroke-opacity="1" stroke-width="2px"></circle>
				  </svg>
        </span>`;
		}
	}
});
